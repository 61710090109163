import React, {useEffect, useState} from 'react';
import Header from '../components/header'
import Footer from '../components/footer';
import VideoThumb from '../components/video-thumb'
import { Link } from "react-router-dom";
import api from '../services/api';
import Paginacao from '../components/paginacao';

function Estrategias() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [info, setInfo] = useState([]);
  var page = window.location.search;
  const video = (videoId) => `/estrategia/${videoId}/`;
  let token = sessionStorage.getItem('pautaberta_token');

  if (!token) {
    window.location.href = '/';
  }

  useEffect(() => {
    let me = sessionStorage.getItem('pautaberta_me');
    me = JSON.parse(me);
    api
    .get('estrategias/?type='+me.type)
    .then(function (response) {
      setIsLoaded(true);
      setItems(response.data.data);
      setInfo(response.data);      
    })
    .catch(function (error) {
      setIsLoaded(true);
      setError(error);
    });
  }, [])
  if (error) {
      return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
      return (
          <Header />
        );
  } else {
      page = page.replace(/[^0-9]/g,'') || 1;

      return (
    <>
      <Header />
      <div className="max-w-7xl px-8 mx-auto">
        <h2 className="text-3xl italic font-light px-8 my-7">Estratégias</h2>
        <div className="grid sm:grid-cols-2 md:grid-cols-4 grid-cols-1 gap-4 max-w-7xl px-8 mx-auto pt-5 pb-10">
          {items.map(item => (!Boolean(Number(item.publico)) && !JSON.parse(sessionStorage.getItem('pautaberta_me')).member ? <a target="_blank" href={(JSON.parse(sessionStorage.getItem('pautaberta_me')).became_member)}><VideoThumb titulo={item.titulo} descricao={item.descricao} urlImagem={item.imagem} /></a> : (
            <Link key={item.id} to={video(item.id)}><VideoThumb titulo={item.titulo} descricao={item.descricao} urlImagem={item.imagem} /></Link>  
          )))}
            <div className="lg:col-span-4 xl:col-span-4 md:col-span-3 sm:col-span-2 text-center">
                <Paginacao pagAtual={page} totPag={info.last_page} module="ebooks" />
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
}

export default Estrategias;