import React, {useEffect, useState} from 'react';
import Header from '../components/header'
import Footer from '../components/footer';
import MinhasAudiencias from '../components/minhas-audiencia';
import api from '../services/api';

function Audiencias() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [types, setTypes] = useState([]);
  let me;
  let userId;
  let token = sessionStorage.getItem('pautaberta_token');

  if (!token) {
    window.location.href = '/';
  }
  me = sessionStorage.getItem('pautaberta_me');
  me = JSON.parse(me);
  
  useEffect(() => {
    getData();

  }, [])

  function getData() {
    me = sessionStorage.getItem('pautaberta_me');
    me = JSON.parse(me);
    let userId = me.id;
    api
    .get('user/'+userId+'/events/?token='+token)
    .then(function (response) {
      setIsLoaded(true);
      setItems(response.data.data);
    })
    .catch(function (error) {
      setIsLoaded(true);
      setError(error);
    });
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <Header />
    );
  } else {
        userId = me.id;
      return (
        <>
          <Header />
          <div className="max-w-7xl px-8 mx-auto">
            <h2 className="text-3xl italic font-light px-8 my-7">Minhas Audiências</h2>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 max-w-7xl px-8 mx-auto pt-5 pb-10 md:min-h-screen">
            { items.map(item => (
              <MinhasAudiencias key={item.id} info={item} />
              )) }
            </div>
          </div>
          <Footer />
        </>
      );
  }
}
export default Audiencias;