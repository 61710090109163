import React, {useEffect, useState} from 'react';
import Header from '../components/header'
import Footer from '../components/footer';
import PodcastMini from '../components/podcast-mini'
import api from '../services/api';
import Paginacao from '../components/paginacao';

function Podcasts() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [info, setInfo] = useState([]);
  let page = window.location.search;
  let token = sessionStorage.getItem('pautaberta_token');

  if (!token) {
    window.location.href = '/';
  }
  
  useEffect(() => {
    let me = sessionStorage.getItem('pautaberta_me');
    me = JSON.parse(me);

    if (page) {
      page = page + '&type='+me.type;
    } else {
      page = '?type='+me.type;
    }

    api
    .get('podcasts/' + page)
    .then(function (response) {
      setIsLoaded(true);
      setItems(response.data.data);
      setInfo(response.data);
    })
    .catch(function (error) {
      setIsLoaded(true);
      setError(error);
    });
  }, [])
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <Header />
    );
  } else {
    page = page.replace(/[^0-9]/g,'') || 1;
      return (
      <>
        <Header />
        <div className="max-w-7xl px-8 mx-auto">
          <h2 className="text-3xl italic font-light px-8 my-7">Podcasts</h2>
          <div className="grid grid-cols-1 gap-4 max-w-7xl px-8 mx-auto pt-5 pb-10">
          {items.map(item => (
            <PodcastMini key={item.id}  titulo={item.titulo} descricao={item.descricao} link={item.link} />
          ))}
          <br clear="all" />
          <div className="text-center">
              <Paginacao pagAtual={page} totPag={info.last_page} module="podcasts" />
          </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default Podcasts;