import React, {useEffect, useState} from 'react';
import Header from '../components/header'
import Footer from '../components/footer';
import AudienciaModel from '../components/audiencia-model';
import api from '../services/api';

function Audiencias() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [types, setTypes] = useState([]);
  let me;
  let userId;
  let token = sessionStorage.getItem('pautaberta_token');

  if (!token) {
    window.location.href = '/';
  }
  me = sessionStorage.getItem('pautaberta_me');
  me = JSON.parse(me);
  
  useEffect(() => {
    getParams();
    getData();

  }, [])

  function getData() {
    let query = '';
    let state = document.querySelector('#state');
    let city = document.querySelector('#city');
    let category = document.querySelector('#category');
    let type = document.querySelector('#type');

    if (state) {
      getCities();
      query = query + '&state='+state.value;
    }

    if (city) {
      query = query + '&city='+city.value;
    }

    if (category) {
      query = query + '&category='+category.value;
    }

    if (type) {
      query = query + '&type='+type.value;
    }

    api
    .get('events/?token='+token+query)
    .then(function (response) {
      console.log(response)
      setIsLoaded(true);
      setItems(response.data.data);
    })
    .catch(function (error) {
      setIsLoaded(true);
      setError(error);
    });
  }

  function getParams()
  {
    api
    .post('params/?token='+token)
    .then(function (response) {
      let data = response.data.data;
      setCategories(data.categories);
      setStates(data.states);
      setTypes(data.types);
    })
    .catch(function (error) {
    });
  }

  function getCities()
  {
    let state = document.querySelector('#state').value;
    api
    .get('cities/' + state + '?token='+token)
    .then(function (response) {
      setCities(response.data.data);
    })
    .catch(function (error) {
    });
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <Header />
    );
  } else {
        userId = me.id;
      return (
        <>
          <Header />
          <div className="max-w-7xl px-8 mx-auto">
            <h2 className="text-3xl italic font-light px-8 my-7">Audiências</h2>
            <div className="bg-gray-100 max-w-7xl px-8 mx-8 rounded border border-gray-200 py-3">
                <h3 className="mb-3 uppercase font-bold">Filtros</h3>
                <form className="w-full max-w-7xl">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block font-bold  tracking-wide text-gray-700 text-xs  mb-2">
                                Estado
                            </label>
                            <div className="relative">
                                <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  name="state"
                                  onChange={getData}
                                  id="state">
                                    <option value="">-- Selecione um estado --</option>
                                    { states.map(state => (
                                    <option key={state.id} value={state.id}>{state.attributes.name}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block font-bold  tracking-wide text-gray-700 text-xs  mb-2">
                                Comarca
                            </label>
                            <div className="relative">
                                <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        name="city"
                                        onChange={getData}
                                        id="city">
                                
                                    <option value="">-- Selecione uma Comarca --</option>
                                    { cities.map(city => (
                                    <option key={city.id} value={city.id}>{city.attributes.name}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block font-bold  tracking-wide text-gray-700 text-xs  mb-2">
                                Área jurídica
                            </label>
                            <div className="relative">
                                <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        name="category"
                                        onChange={getData}
                                        id="category">
                                
                                    <option value="">-- Selecione uma área --</option>
                                    { categories.map(category => (
                                    <option key={category.id} value={category.id}>{category.attributes.name}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block font-bold  tracking-wide text-gray-700 text-xs  mb-2">
                                Tipo de audiência
                            </label>
                            <div className="relative">
                                <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                name="type"
                                onChange={getData}
                                id="type">
                                    <option value="">-- Selecione um tipo --</option>
                                    { types.map(type => (
                                    <option key={type.id} value={type.id}>{type.attributes.name}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        {/* <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <button type="button" className="inline-flex float-left text-sm justify-center py-2 px-3 shadow-sm text-sm rounded-md" onClick={getData}>Buscar</button>
                        </div> */}
                    </div>
                </form>
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 max-w-7xl px-8 mx-auto pt-5 pb-10">
            { items.map(item => (
              <AudienciaModel key={item.id} id={item.id} userId={userId} nome={item.attributes.user.first_name + ' ' + item.attributes.user.last_name} email={item.attributes.user.email} juizado={item.attributes.location} audiencia={item.attributes.title} data={item.attributes.date} avatar={item.attributes.userAvatar} participantes={item.attributes.invitationsCount} attrs={item.attributes} />
              )) }
            </div>
          </div>
          <Footer />
        </>
      );
  }
}
export default Audiencias;