import React from "react";

function PodcastMini(props) {
    return (
        <div className="h-10 rounded-lg relative border border-gray-300 h-auto py-5 px-5 md:col-span-2 sm:col-span-2">
            <p className="tracking-wide font-bold text-gray-700 mb-5 text-azul-escuro">{props.titulo}</p>
            <p className="text-gray-700 text-sm mt-2 mb-3">{props.descricao}</p>
            <div className="post__content" dangerouslySetInnerHTML={{__html: props.link}}></div>
        </div>
    )
};
export default PodcastMini;