import React from 'react';
import api from '../services/api';

function invitations(eventId, userId) {
    let token = sessionStorage.getItem('pautaberta_token');

    api
    .get('invitations/?token='+token+'&event_id='+eventId+'&user_id='+userId)
    .then(function (response) {
        alert('Solicitação enviada');
        window.location.reload();
    })
    .catch(function (error) {
    });
}
function AudienciaModel(props) {
    return (
        <>
        {props.attrs.requestedByUser ? '' :
        <div className="w-full overflow-hidden border rounded mb-8 flex flex-col">
            <div className="px-4 pt-3 pb-4 border-b border-gray-300 bg-gray-100 w-full">
                <div className="flex items-center pt-2">
                    <div className="bg-cover bg-center w-10 h-10 rounded-full mr-3">
                    <img
                    className="h-15 w-12 rounded-full"
                    src={props.avatar}
                    alt=""
                    />
                    </div>
                    <div>
                        <p className="font-bold text-gray-900 text-sm">{props.nome}</p>
                        <p className="text-gray-400 text-xs">{props.email}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col flex-grow">
                <p className="text-gray-700 mt-5 ml-2 text-sm flex-grow">{props.juizado}</p>
                <p className="tracking-wide text-lg flex-grow font-bold text-gray-700 mt-5 ml-2 text-azul-escuro italic ">{props.audiencia}</p>
                <p className="text-gray-700 mt-5 ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3 pt-1 text-azul-ciano" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>{props.data}
                </p>
            </div>
            
            <div className="border-t py-2 px-2 flex w-full mt-3 justify-between">

                <button
                    type="button"
                    className="inline-flex float-left text-sm justify-center py-2 px-3 shadow-sm text-sm rounded-md hover:text-white hover:bg-azul-escuro bg-azul-ciano text-white"
                    onClick={(e) => invitations(props.id, props.userId)}
                >
                    Solicitar participação
                </button>
                 <p className="text-xs text-gray-400 pt-3 inline-flex float-right mr-2">{props.participantes} Participantes</p>
            </div> 
        </div>}
        </>
    )
};
export default AudienciaModel;