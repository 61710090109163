function MinhasAudiencias(props) {
    return (
        <>
        <div className="w-full overflow-hidden border rounded mb-8">
            <div className="px-4 pt-3 pb-4 border-b border-gray-300 bg-gray-100">
                <div className="flex items-center pt-2">
                    <div className="bg-cover bg-center w-10 h-10 rounded-full mr-3">
                    <img
                    className="h-15 w-12 rounded-full"
                    src={props.info.attributes.userAvatar}
                    alt=""
                    />
                    </div>
                    <div>
                        <p className="font-bold text-gray-900 text-sm">{props.info.attributes.eventLawyerName}</p>
                        <p className="text-gray-300 text-xs">{props.info.attributes.user.email}</p>
                    </div>
                </div>
            </div>
            <p className="text-gray-700 mt-5 ml-2 text-sm">{props.info.attributes.eventLocation}</p>
            <p className="tracking-wide text-lg font-bold text-gray-700 mt-5 ml-2 text-azul-escuro italic ">{props.info.attributes.eventTitle}</p>
            <p className="text-gray-700 mt-5 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3 pt-1 text-azul-ciano" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>{props.info.attributes.event.date}
            </p>
            <div className="border-t my-6 px-2">
                <button
                    type="submit"
                    className={`my-2 inline-flex float-left text-sm justify-center py-2 px-3 shadow-sm text-sm rounded-md hover:text-white ${props.info.attributes.statusClass} text-white`}
                >
                    {props.info.attributes.statusName}
                </button>
                <p className="text-xs text-gray-400 pt-5 float-right mr-1">{props.info.attributes.event.invitations_count} Participantes</p>
            </div>
        </div>
        </>
    )
};
export default MinhasAudiencias;