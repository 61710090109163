import React, {useEffect, useState} from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import ad from '../assets/media/images/ad.png'
import api from '../services/api';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

function Inicio() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [dataMe, setMe] = useState([]);
  const [advertisement, setAdvertisement] = useState([]);
  const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  let token = sessionStorage.getItem('pautaberta_token');
  let me;
  let today = new Date();
  let date = today.getDate() + '/' + (months[today.getMonth()]) + '/' + today.getFullYear();
  let gender;
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    prevArrow: <span className="iconify" data-icon="bi:arrow-left-circle-fill" data-inline="false"></span>,
    nextArrow: <span className="iconify" data-icon="bi:arrow-left-circle-fill" data-inline="false" data-flip="horizontal"></span>
  };

  if (!token) {
    window.location.href = '/';
  }

  let info = JSON.parse(sessionStorage.getItem('pautaberta_me'));

  useEffect(() => {
    me = sessionStorage.getItem('pautaberta_me');
    me = JSON.parse(me);

    gender = me.attributes.gender;
    api
    .get('lawyers/?gender='+gender)
    .then(function (response) {
      setIsLoaded(true);
      setMe(me);
      setItems(response.data);
      getAdvertisement();
    })
    .catch(function (error) {
      setIsLoaded(true);
      setError(error);
    });
  }, [])

  function getAdvertisement()
  {
    api
    .get('advertisement/?token='+token)
    .then(function (response) {
      setAdvertisement(response.data);
    })
    .catch(function (error) {
    });
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <Header />
    );
} else {
  let arrAdvertisement = [];
  if (typeof advertisement.data == 'object' ? advertisement.data.length > 0 : false) {
    advertisement.data.map(item => (
      arrAdvertisement.push(<a target="_blank" href={(item.link_url)}><div className="each-slide rounded-lg mx-auto">
        <div style={{'backgroundImage': `url(${item.image})`}}></div>
    </div></a>)
    ));
  }
  else {
    arrAdvertisement.push(<div className="each-slide rounded-lg mx-auto">
      <div style={{'backgroundImage': `url(${ad})`}}>
      </div>
    </div>)
  }
  return (
    <>
      <Header />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 xl:max-w-7xl max-w-5xl px-8 mx-auto pt-5">
        <div className="lg:col-span-3 md:col-span-2 sm:col-span-2 rounded-lg items-center mt-3 mb-6">
            <img
              id="avatar"
              className="h-16 w-16 rounded-full float-left"
              src={info.attributes.avatar}
              alt=""
            />
            <h2 className="text-3xl italic font-light ml-4 block mt-3">Olá, {info.attributes.firstName}. <span className="text-azul-escuro">Seja bem vind{gender ? 'a': 'o'}</span></h2>
            <h3 className="block">Hoje é {date} </h3>
        </div>
        <div className="w-full rounded-lg border relative lg:col-span-1 md:col-span-1 sm:col-span-2 border-gray-300 py-5 px-5">
          <h3 className="text-lg uppercase">Audiências</h3>
          <p className="text-sm my-4">Confira as audiências disponíveis para Jaboatão dos Guararapes!</p>
          <Link className="hover:underline" to="/audiencias">Ir para audiências</Link>
        </div>
        <div className="w-full rounded-lg border relative lg:col-span-1 md:col-span-1 sm:col-span-2 border-gray-300 py-5 px-5">
          <h3 className="text-lg uppercase">Estratégias</h3>
          <p className="text-sm my-4">Confira aqui tudo que você não vai aprender na faculdade!</p>
          <Link className="hover:underline" to="/estrategias">Ir para estratégias</Link>
        </div>
        <div className="w-full h-30 row-span-2 relative rounded-lg sm:col-span-2 md:col-span-2 lg:col-span-1">
            <div className="slide-container">
              <Slide easing="ease" {...properties}>
                {arrAdvertisement}
              </Slide>
            </div>
          {/* <img
            className="rounded-lg mx-auto"
            src={ad}
            alt=""
          /> */}
        </div>
        <div className="h-10 rounded-lg relative border border-gray-300 h-auto py-5 px-5 md:col-span-2 sm:col-span-2">
          <h3 className="text-lg uppercase">Novidades</h3>
          <p className="text-sm my-4">Tem um e-book novo esperando por você!</p>
          <Link className="hover:underline" to="/ebooks">Ir para sessão de ebooks</Link>
        </div>

        <div className="h-10 rounded-lg relative border border-gray-300 h-auto py-5 px-5 md:col-span-2 lg:col-span-3">
          <h3 className="text-lg uppercase">Outros membros da comunidade</h3>
          <div className="grid grid-cols-2 lg:grid-cols-8 md:grid-cols-4 mt-5">
          { items.map(item => (
            <div className="text-center mb-8 sm:mb-8 md:mb-5 lg:mb-0">  
              <img
                className="h-14 w-14 rounded-full mx-auto"
                src={item.avatar}
                alt=""
              />
              <p className="text-sm">{item.first_name + ' ' + item.last_name}</p>
            </div>
          )) }
          </div>  
        </div>
        

      </div>
      <Footer />
    </>
  );
}
}


export default Inicio;