import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect} from 'react'
import logo from '../assets/media/images/marca.png'
import appleStore from '../assets/media/images/disponivel-app-store.png'
import googlePlay from '../assets/media/images/disponivel-google-play.png'
import api from '../services/api';
import {isMobile} from 'react-device-detect';
import LoginMobile from './login-mobile';
import { Link } from 'react-router-dom';

function Logar(e)
{
	e.preventDefault();
	  api
	  .post('user/login', {
		  email: document.getElementById('email').value,
		  password: document.getElementById('password').value
		})
	  .then(function (response) {
		  if (response.data.status === 'fail') {
			  //console.log(response)
			  alert(response.data.message);
			  return false;
		  }
			sessionStorage.setItem('pautaberta_me', JSON.stringify(response.data.me));
			sessionStorage.setItem('pautaberta_token', response.data.token);
			window.location.href = 'inicio';
		  

	  })
	  .catch(function (error) {
		  console.log(error);
	  });
}

function Login() {

	sessionStorage.clear();

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
	document.querySelector("#resetSenha1").style.display = "inline";
	document.querySelector("#resetSenha2").style.display = "none";
	setIsOpen(false)
  }

  function resetSenha() {
	//
	api.post("user/reset", {
		email: document.getElementById('email1').value
	}).then(function(response){
		if (response.data.status === 'fail') {
			alert(JSON.parse(response.data.a).error);
			return false;
		}
		alert("E-mail enviado com sucesso! Verifique sua caixa de entrada.")
		//setIsOpen(false)
		document.querySelector("#resetSenha1").style.display = "none";
		document.querySelector("#resetSenha2").style.display = "inline";
		document.getElementById('email1').value = ''
	}).catch(function(error){
		console.log(error.response)
		setIsOpen(false)
	})

  }

  function toggleReset(){
	  if(document.querySelector("#resetSenha1").style.display == "none"){
		document.querySelector("#resetSenha1").style.display = "inline";
		document.querySelector("#resetSenha2").style.display = "none";
	  }else{
		document.querySelector("#resetSenha1").style.display = "none";
		document.querySelector("#resetSenha2").style.display = "inline";
	  }
  }

  function resetSenhaUser() {
	//
	
	api.post("user/reset/" + document.querySelector("#code").value, {
		password: document.getElementById('newpassword').value
	}).then(function(response){
		if (response.data.status === 'fail') {
			alert(response.data.message);
			return false;
		}
		document.querySelector("#resetSenha1").style.display = "inline";
		document.querySelector("#resetSenha2").style.display = "none";
		setIsOpen(false)
		alert("Senha redefinida com sucesso. Faça seu login!")
		console.log(response)
	}).catch(function(error){
		console.log(error.response)
	})

  }


  function openModal() {
	setIsOpen(true)
  }

  const size = useWindowSize();
  function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
	  width: undefined,
	  height: undefined,
	});
	useEffect(() => {
	  // Handler to call on window resize
	  function handleResize() {
		// Set window width/height to state
		setWindowSize({
		  width: window.innerWidth,
		  height: window.innerHeight,
		});
	  }
	  // Add event listener
	  window.addEventListener("resize", handleResize);
	  // Call handler right away so state gets updated with initial window size
	  handleResize();
	  // Remove event listener on cleanup
	  return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
  }
  if (isMobile) {return (<LoginMobile />)}
  return (
	<>
	<div className="bg-login flex absolute bg-cover bg-no-repeat bg-gray-50 min-h-screen w-full"></div>
	<div className="flex z-10 min-h-screen bg-gradient-to-tl from-azul-escuro to-azul-ciano py-3 relative top-0 left-0 w-full sm:max-w-sm">
	  <div className="flex flex-col py-5 w-full">
		<img src={logo} alt="Marca PautAberta" className="w-44 mx-auto" />
		<form onSubmit={Logar} action="#" method="POST" className="flex flex-col pt-6 flex-grow">
		  <div className="py-5 flex flex-col mx-8">
			<label htmlFor="email" className="block text-sm font-medium text-white">
			  E-mail
			</label>
			<input
			  type="text"
			  name="email"
			  id="email"
			  required
			  autoComplete="email"
			  className="mt-1 focus:ring-azul-ciano focus:border-azul-ciano block w-full sm:text-md border-azul-ciano rounded-md h-12"
			/>

			<label htmlFor="password" className="block text-sm font-medium text-white mt-6">
			  Senha
			</label>
			<input
			  type="password"
			  name="password"
			  id="password"
			  required
			  autoComplete="password"
			  className="mt-1 focus:ring-azul-ciano focus:border-azul-ciano block w-full sm:text-md border-azul-ciano rounded-md h-12"
			/>
			  <div className="text-sm font-medium text-white text-center pt-2">* Entre com os dados do aplicativo</div>
		  </div>
		  <div className="flex justify-end py-3 text-gray-400 text-right mb-2">
			 {/* <Link to="/inicio" className="text-white hover:underline mr-10">Passar</Link>
			 <Link onClick={openModal} to="/"className="text-white hover:underline" >Esqueci minha senha</Link>*/ }
		  </div>
		  <div className="flex justify-end mx-8 center-things">
			<button
			  type="submit"
			  className="py-3 px-11 text-sm font-medium rounded-md text-white hover:text-black text-lg bg-dourado focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
			>
			  ENTRAR
			</button>
			<div className="divider1"></div>
			<Link onClick={openModal} to="/"className="text-white hover:underline" >Esqueci minha senha</Link>

		  </div>
		  {/*<label className="text-sm font-medium text-white text-center pt-2">(Solicite a redefinição de senha pelo aplicativo)</label>*/}

			<div className="text-center py-5 px-2 block z-20 inset-y-2/3 inset-x-2/4 mx-auto mt-8">
				{size.height>639
				? <p className="text-white mb-4 mx-8 font-small">Para fazer parte da nossa comunidade, baixe já nosso aplicativo</p>
				: <p className="text-white mb-4 mx-8 font-small hidden">Para fazer parte da nossa comunidade, baixe já nosso aplicativo</p>
				}

				{size.width>336
				?<a href="https://play.google.com/store/apps/details?id=com.pautaberta"
					type="submit"
					className="py-3 px-2 mx-1 w-40 text-sm font-light rounded-md text-white hover:text-black text-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					{/* <FaGooglePlay className="float-left mt-1" /> Google Play */}
					<img src={googlePlay} alt="Google Play" className="float-left" />
				</a>
				:<a href="https://play.google.com/store/apps/details?id=com.pautaberta"
				type="submit"
				className="py-3 mx-1 w-32 text-xs font-light rounded-md text-white hover:text-black text-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					{/* <FaGooglePlay className="float-left mt-1" /> Google Play */}
					<img src={googlePlay} alt="Google Play" className="float-left" />
				</a>
				}
				{size.width>336
				?<a href="https://apps.apple.com/br/app/pautaberta/id1372632044"
					type="submit"
					className="py-3 px-2 mx-1 w-40 text-sm font-small rounded-md text-white hover:text-black text-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					{/* <FaApple className="float-left mt-1"/> Appstore */}
					<img src={appleStore} alt="Apple Store" className="float-left" />
				</a>
				:<a href="https://apps.apple.com/br/app/pautaberta/id1372632044"
				type="submit"
				className="py-3 mx-1 w-32 text-sm font-small rounded-md text-white hover:text-black text-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					{/* <FaApple className="float-left mt-1"/> Appstore */}
					<img src={appleStore} alt="Apple Store" className="float-left" />
				</a>}

			</div>
		</form>
	  </div>
	</div>

  
	{/* POP-UP DE ESQUECI MINHA SENHA */}

	  <Transition appear show={isOpen} as={Fragment}>
		<Dialog
		  as="div"
		  className="fixed inset-0 z-10 overflow-y-auto bg-azul-escuro bg-opacity-75"
		  onClose={closeModal}
		>
		  <div className="min-h-screen px-4 text-center">
			<Transition.Child
			  as={Fragment}
			  enter="ease-out duration-300"
			  enterFrom="opacity-0"
			  enterTo="opacity-100"
			  leave="ease-in duration-200"
			  leaveFrom="opacity-100"
			  leaveTo="opacity-0"
			>
			  <Dialog.Overlay className="fixed inset-0" />
			</Transition.Child>

			{/* This element is to trick the browser into centering the modal contents. */}
			<span
			  className="inline-block h-screen align-middle"
			  aria-hidden="true"
			>
			  &#8203;
			</span>
			<Transition.Child
			  as={Fragment}
			  enter="ease-out duration-300"
			  enterFrom="opacity-0 scale-95"
			  enterTo="opacity-100 scale-100"
			  leave="ease-in duration-200"
			  leaveFrom="opacity-100 scale-100"
			  leaveTo="opacity-0 scale-95"
			>
				<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
					<div id="resetSenha1">
						<Dialog.Title
					  as="h3"
					  className="text-lg font-medium leading-6 text-gray-900"
					>
					  ESQUECEU SEU SENHA?
					</Dialog.Title>
					<div className="mt-2">
					  <p className="text-sm text-gray-500">
						Digite seu endereço de e-mail de cadastro para receber um
						tutorial de como recuperar sua senha.
					  </p>
					</div>
					<div className="col-span-2 sm:col-span-2 my-6">
						<input
						  type="text"
						  name="email"
						  id="email1"
						  autoComplete="email"
						  placeholder="E-mail"
						  className="mt-1 focus:ring-indigo-500 focus:border-azul-ciano block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
						/>
					  </div>
					  <div id="resetbuttons" className="mt-4">
					  <button
						type="button"
						className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-azul-escuro border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
						onClick={resetSenha}
					  >
						Recuperar senha
					  </button>

					  <button
						type="button"
						className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-dourado border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
						onClick={toggleReset}
					  >
						Já tenho o código
					  </button>
					</div>
				  </div>
				  <div id="resetSenha2">
			  		<Dialog.Title
					  as="h3"
					  className="text-lg font-medium leading-6 text-gray-900"
					>
					  ESQUECEU SEU SENHA?
					</Dialog.Title>
					<div className="mt-2">
				  		<p className="text-sm text-gray-500">
							Você recebeu um código no e-mail enviado. Digite-o no campo abaixo e, no próximo campo, insira sua nova senha.
				  		</p>
					</div>

					<div className="col-span-2 sm:col-span-2 my-6">
						<input
						  type="text"
						  name="code"
						  id="code"
						  autoComplete="code"
						  placeholder="Código recebido no e-mail"
						  className="mt-1 focus:ring-indigo-500 focus:border-azul-ciano block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
						/>

						<input
						  type="password"
						  name="newpassword"
						  id="newpassword"
						  autoComplete="password"
						  placeholder="Sua Nova senha"
						  className="mt-1 focus:ring-indigo-500 focus:border-azul-ciano block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
						/>
				  </div>

				  <div id="resetbuttons" className="mt-4">
				  	<button
						type="button"
						className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-azul-escuro border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
						onClick={resetSenhaUser}
				  	>
						Redefinir senha
				  	</button>
					  <button
						type="button"
						className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-dourado border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
						onClick={toggleReset}
					  >
						Ainda não tenho o código
					  </button>
					</div>
			  </div>
			  </div>
			</Transition.Child>
		  </div>
		</Dialog>
	  </Transition>
	</>
  
  );
}

export default Login;
