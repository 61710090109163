import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import logo2 from '../assets/media/images/marca2.png';
import logosymbol from '../assets/media/images/marca-symbol.png';

let member = sessionStorage.getItem('pautaberta_me');
const navigation = [
  { public: true, name: 'Início', href: '/inicio', current: false, icon: 'bx:bx-home-alt' },
  { public: true, name: 'Estratégias', href: '/estrategias', current: false, icon: 'clarity:video-gallery-line' },
  { public: true, name: 'Audiências', href: '/audiencias', current: false, icon: 'bi:briefcase' },
  { public: true, name: 'Minha Agenda', href: '/minhas-audiencias', current: false, icon: 'ant-design:calendar-filled' },
  { public: false, name: 'E-books', href: '/ebooks', current: false, icon: 'uil:notebooks' },
  { public: false, name: 'Material de Apoio', href: '/planilhas', current: false, icon: 'healthicons:spreadsheets-outline' },
  { public: true, name: 'Podcasts', href: '/podcasts', current: false, icon: 'lucide:podcast' },
];

let me;
let avatar;

me = sessionStorage.getItem('pautaberta_me');
if(me !== ""){
  me = JSON.parse(me);
}

if (me) {
  avatar = me.attributes.avatar;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div className="bg-gradient-to-l from-azul-escuro to-azul-ciano">
            <div className="xl:max-w-7xl max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-azul-escuro focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Abrir menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src={logosymbol}
                      alt="PautAberta"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={logo2}
                      alt="PautAberta"
                    />
                  </div>
                  
                </div>
                {/*<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 text-white">
                  Somos <span className="font-bold">&nbsp;{me.total}&nbsp;</span> membros
                    </div>*/}
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Abrir menu do usuário</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={avatar}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                          >
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/meusdados"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  <span className="iconify float-left mr-1 mt-1" data-icon="bi:person-fill" data-flip="horizontal"></span>
                                  Meus dados
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  <span className="iconify float-left mr-1 mt-1" data-icon="uiw:logout"></span>
                                  Sair
                                </Link>
                              )}
                            </Menu.Item>
                            {/* <Menu.Item>
                              {({ active }) => (
                                <Link
                                to="/minhas-audiencias"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                <span className="iconify float-left mr-1 mt-1" data-icon="ant-design:calendar-filled"></span>
                                Minhas Audiências
                              </Link>
                              )}
                            </Menu.Item> */}
                            {/* <Menu.Item>
                              {({ active }) => (
                                <Link
                                  href="/"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  <span className="iconify float-left mr-1 mt-1" data-icon="uiw:logout"></span>
                                  Sair
                                </Link>
                              )}
                            </Menu.Item> */}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-azul-escuro text-white' : 'text-white hover:bg-azul-escuro hover:text-white',
                      'block px-3 py-1 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <span className="iconify inline mr-1 mt-1" data-icon={item.icon}></span>
                    {item.name} 
                  </a>
                ))}
              </div>
            </Disclosure.Panel>
          </div>
          <div as="nav" className="border-b w-full mx-auto text-center">
            <div className="pt-3 pb-3 xl:max-w-7xl lg:max-w-5xl md:w-full sm:w-full xl:mx-auto lg:mx-auto hidden sm:block md:block lg:block xl:block 2xl:block">
              {navigation.map((item) => (
                {/*!item.public && !JSON.parse(sessionStorage.getItem('pautaberta_me')).member ? 
                <a 
                  href={(JSON.parse(sessionStorage.getItem('pautaberta_me')).became_member)}
                  target="_blank"
                  className={classNames(
                    item.current ? 'bg-azul-escuro text-dourado' : 'transition duration-200 ease-in-out text-azul-escuro transform hover:bg-gray-200','xl:px-1 xl:mx-10 xl:py-2 px-5 mx-8 lg:mx-8 md:mx-4 sm:mx-1 py-2 rounded-md text-sm font-medium'
                  )}
                  >
                  <span className="iconify inline mr-1 mt-1" data-icon={item.icon}></span>
                  {item.name}
                  </a> :*/},
                  <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current ? 'bg-azul-escuro text-dourado' : 'transition duration-200 ease-in-out text-azul-escuro transform hover:bg-gray-200','xl:px-1 xl:mx-10 xl:py-2 px-5 mx-8 lg:mx-8 md:mx-4 sm:mx-1 py-2 rounded-md text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <span className="iconify inline mr-1 mt-1" data-icon={item.icon}></span>
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}