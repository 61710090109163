function PlanilhaThumb(props) {
    return (
      <div className="border rounded">
      <img src={props.thumb} alt={props.titulo} className="rounded-t w-full" />
      <div className="px-2 h-auto">
        <p className="tracking-wide text-base font-bold text-gray-700 mt-5 h-18 overflow-hidden">{props.titulo}</p>
        <p className="text-gray-700 mt-2 h-12 overflow-hidden">{props.descricao}</p>
      </div>
      <div className="border-t mt-4 p-4 text-center bottom-0">
          <a href={props.download}
            download
            className="inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-azul-ciano hover:bg-azul-escuro"
          >
            Download
          </a>
      </div>
  </div>
)
};
export default PlanilhaThumb;