import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Login from './pages/login';
import Inicio from './pages/inicio';
import Ebooks from './pages/ebooks';
import Estrategias from './pages/estrategias';
import Planilhas from './pages/planilhas';
import Audiencias from './pages/audiencias';
import Podcasts from './pages/podcasts';
import Meusdados from './pages/meusdados';
import MinhasAudiencias from './pages/minhas-audiencias';
import Video from './pages/video';

const App = () =>{
  return(
      <div>
        <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/inicio">
              <Inicio />
            </Route>
            <Route path="/ebooks">
              <Ebooks />
            </Route>
            <Route path="/estrategias">
              <Estrategias />
            </Route>
            <Route path="/planilhas">
              <Planilhas />
            </Route>
            <Route path="/audiencias">
              <Audiencias />
            </Route>
            <Route path="/podcasts">
              <Podcasts />
            </Route>
            <Route path="/meusdados">
              <Meusdados />
            </Route>
            <Route path="/minhas-audiencias">
            <MinhasAudiencias />
            </Route>
            <Route path="/estrategia/:idYT/:idRelated?">
              <Video />
            </Route>
        </Switch>
      </Router>
    </div>
  );
}
export default withRouter(App);