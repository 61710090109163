import React, {useEffect, useState, useRef} from 'react';
import Header from '../components/header'
import Footer from '../components/footer';
import { useForm } from "react-hook-form";


function Meusdados() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const { register,  handleSubmit, watch, formState: { errors }, } = useForm();
    const password = useRef({});
    const onSubmit = data => console.log(data);
    let token = sessionStorage.getItem('pautaberta_token');

    password.current = watch("password", "");

    if (!token) {
        window.location.href = '/';
    }

    useEffect(() => {
        let me = sessionStorage.getItem('pautaberta_me');
        setItems(JSON.parse(me));
        setIsLoaded(true);
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <Header />
          );
    } else {
  return (
    <>
        <Header />
        <div className="max-w-7xl px-3 mx-auto pb-10" >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-5 sm:mt-0 pb-5">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1 pt-10 ">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">MEUS DADOS</h3>
                                <p className="mt-1 text-sm text-gray-600">Aqui você poderá alterar todos os dados inseridos anteriormente.</p>
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                                Primeiro nome
                                            </label>
                                            <input
                                                type="text"
                                                name="first_name"
                                                id="first_name"
                                                autoComplete="given-name"
                                                {...register("firstName",{required: true})}
                                                defaultValue={items.attributes.firstName}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                                                Último nome
                                            </label>
                                            <input
                                                type="text"
                                                name="last_name"
                                                id="last_name"
                                                defaultValue={items.attributes.lastName}
                                                autoComplete="family-name"
                                                {...register("lastName",{required: true})}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <div className="col-span-5 sm:col-span-3">
                                            <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                                                E-mail
                                            </label>
                                            <input
                                                type="text"
                                                name="email_address"
                                                id="email_address"
                                                autoComplete="email"
                                                {...register("email",{required: true})}
                                                defaultValue={items.attributes.email}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <div className="col-span-6 sm:col-span-2">
                                            <label htmlFor="sexo" className="block text-sm font-medium text-gray-700">
                                                Sexo
                                            </label>
                                            <select
                                                id="sexo"
                                                name="sexo"
                                                autoComplete="sexo"
                                                {...register("gender",{required: true})}
                                                defaultValue={items.attributes.gender}
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="0">Masculino</option>
                                                <option value="1">Feminino</option>
                                            </select>
                                        </div>
                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="telefone" className="block text-sm font-medium text-gray-700">
                                                Telefone
                                            </label>
                                            <input
                                                type="text"
                                                name="telefone"
                                                id="telefone"
                                                {...register("phone",{required: true})}
                                                defaultValue={items.attributes.phone}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-5 sm:mt-0  pb-5">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1 pt-10 ">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">MEU PERFIL (ESTUDANTE)</h3>
                                <p className="mt-1 text-sm text-gray-600">Altere dados referentes a sua formação educacional.</p>
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                                <div className="shadow overflow-hidden sm:rounded-md">
                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-4">
                                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                                    Faculdade / Universidade
                                                </label>
                                                <input
                                                    type="text"
                                                    name="college"
                                                    id="college"
                                                    autoComplete="college"
                                                    defaultValue={items.attributes.college}
                                                    {...register("college",{required: true})}
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>                                
                                            <div className="col-span-6 sm:col-span-2">
                                                <label htmlFor="periodo" className="block text-sm font-medium text-gray-700">
                                                    Período
                                                </label>
                                                <select
                                                    id="collegeTerm"
                                                    name="collegeTerm"
                                                    autoComplete="collegeTerm"
                                                    value={items.attributes.collegeTerm}
                                                    {...register("collegeTerm",{required: true})}
                                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                >
                                                    <option value="1">1º período</option>
                                                    <option value="2">2º período</option>
                                                    <option value="3">3º período</option>
                                                    <option value="4">4º período</option>
                                                    <option value="5">5º período</option>
                                                    <option value="6">6º período</option>
                                                    <option value="7">7º período</option>
                                                    <option value="8">8º período</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-azul-escuro hover:bg-azul-ciano hover:text-azul-escuro focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Salvar alterações
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="px-5 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1 pt-10 ">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">MINHA SENHA</h3>
                            <p className="mt-1 text-sm text-gray-600">Para sua segurança, altere regularmente sua senha.</p>
                            <p className="mt-5 text-sm text-gray-600">Caso altere sua senha, você será deslogado automaticamente e terá que logar novamente usando a senha nova</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-2">
                                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                                Senha antiga
                                            </label>
                                            <input
                                                type="password"
                                                id="current_password"
                                                autoComplete="given-name"
                                                aria-invalid={errors.current_password ? "true" : "false"}
                                                {...register('current_password',{
                                                    required: "Campo obrigatório",
                                                    minLength: {
                                                        value: 6,
                                                        message: "Sua senha deve ter pelo menos 6 caracteres"
                                                    }
                                                    })}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errors.current_password && (<p className="text-sm block-wddd">{errors.current_password.message}</p>)}
                                        </div>                                
                                        <div className="col-span-6 sm:col-span-2">
                                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                                Senha nova
                                            </label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                aria-invalid={errors.password ? "true" : "false"}
                                                autoComplete="given-name"
                                                {...register('password',{
                                                    required: "Campo obrigatório",
                                                        minLength: {
                                                            value: 6,
                                                            message: "A sua nova senha deve ter pelo menos 6 caracteres"
                                                        }
                                                    })}
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errors.password && (<p className="text-sm">{errors.password.message}</p>)}
                                        </div>                                
                                        <div className="col-span-6 sm:col-span-2">
                                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                                Confirmar senha nova
                                            </label>
                                            <input
                                                type="password"
                                                name="password_confirmation"
                                                id="password_confirmation"
                                                aria-invalid={errors.password_confirmation ? "true" : "false"}
                                                autoComplete="given-name"
                                                {...register('password_confirmation',{
                                                    validate: value => value === password.current || "As senhas não conferem"
                                                    })}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errors.password_confirmation && (<p className="text-sm">{errors.password_confirmation.message}</p>)}
                                        </div>                                
                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-azul-escuro hover:bg-azul-ciano hover:text-azul-escuro focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Salvar alterações
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  );
}
}
export default Meusdados;