function VideoThumb(props) {
    return (
        <div className="border rounded p-1">
            {props.interna ? <p className="tracking-wide text-sm font-bold text-gray-700 my-2 ml-2">{props.titulo}</p> : ''}
            <img src={props.urlImagem} alt={props.titulo} title={props.titulo} className="w-full rounded-t" />
            {!props.interna ? <p className="tracking-wide text-sm font-bold text-gray-700 my-2 ml-2">{props.titulo}</p> : ''}
            <h3 className="tracking-wide font-extralight mt-2 text-sm ml-2">{props.date}</h3>
            {props.descricao != '' ? <p className="tracking-wide text-sm my-5 ml-2">{props.descricao}</p> : ''}
        </div>
    )
};
export default VideoThumb;