import React, {useEffect, useState, Fragment} from 'react';
import ReactStars from "react-rating-stars-component";
import Header from '../components/header'
import Footer from '../components/footer';
import VideoThumb from '../components/video-thumb'
import {Link, useParams} from "react-router-dom";
import api from '../services/api';
import { render } from "react-dom";
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from "react-hook-form";

function Video(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  let {idYT} = useParams();
  let {idRelated} = useParams();
  const video = (videoId) => `/estrategia/${videoId}/`;
  const { register, handleSubmit, formState: { errors }, } = useForm();

  const onSubmit = data => {

    let me = sessionStorage.getItem('pautaberta_me');
    me = JSON.parse(me);
    api
	  .post('comments', {
		  video_id: idYT,
		  user_id: me.id,
		  comentario: data.comentario,
		})
	  .then(function (response) {
		  if (response.data.status === 'fail') {
			  alert(response.data.message);
			  return false;
		  } else {
        alert(response.data.message);
        document.getElementById('comentario').value = '';
        closeModal();
			  return false;
		  }
	  })
	  .catch(function (error) {
		  console.log(error);
	  });

  };

  function ratingStars()
  {
    const ratingChanged = (newRating) => {
      console.log(newRating);
    };
     
    render(
      <ReactStars
        count={5}
        onChange={ratingChanged}
        size={24}
        activeColor="#ffd700"
      />,
     
      document.getElementById("rating-stars")
    );
  }

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
  setIsOpen(false)
  }

  function openModal() {
  setIsOpen(true)
  }

  useEffect(() => {
    let me = sessionStorage.getItem('pautaberta_me');
    me = JSON.parse(me);

    if (idRelated !== undefined) {
      idYT = idYT + '/' + idRelated;
    }

    api
    .get('estrategias/'+idYT+'?type='+me.type)
    .then(function (response) {
      setIsLoaded(true);
      setItems(response.data[0]);
      ratingStars();
    })
    .catch(function (error) {
      setIsLoaded(true);
      setError(error);
    });
    
  }, [])
  if (error) {
    return (
      <div>Error: {error.message}</div>
    );
  } else if (!isLoaded) {
    return (
        <Header />
      );
  } else {
    if(items.publico !== undefined){
      if(!JSON.parse(sessionStorage.getItem('pautaberta_me')).member && !Boolean(Number(items.publico))){
        window.location.href = JSON.parse(sessionStorage.getItem('pautaberta_me')).became_member
      }
    }

    var urlYT = items.urlyt;
    var relacionados = [];
    var comentarios = [];
    console.log(items)
    if (items){
      if (items.relacionados) {
        items.relacionados.map(item => (
          relacionados.push(<div className="max-w-7xl mx-auto pt-5">
              <div className="flex flex-wrap overflow-hidden md:-mx-3 lg:-mx-2 xl:-mx-2">
                <a key={item.id} href={`/estrategia/${item.id}/${item.parent_id}/`} className="w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4"><VideoThumb titulo={item.titulo} urlImagem={item.imagem} /></a>
              </div>
          </div>)
        ))
      }
      if(items.comentarios) {
        console.log(items.comentarios)

        items.comentarios.map(item => (
          comentarios.push(
            <li id={`box-${item.id}`} className="border rounded p-1 mt-5 p-2">
              <div>
                <div>
                  <img
                    id="avatar"
                    className="h-12 w-12 rounded-full float-left"
                    src={item.usuario.avatar}
                    alt=""
                  />
                  {item.usuario.first_name} {item.usuario.last_name}<br /><span className="text-xs">{item.data}</span>
                </div>
                <div className="text-sm" dangerouslySetInnerHTML={{__html: item.comentario}}></div>
              </div>
            </li>
          )
        ));
      }
    }
    
    return (
    <>
      <Header />
      <div className="max-w-7xl px-8 mx-auto pt-5 pb-10">
      <div className="border rounded p-1">
            <iframe width="100%" height="630" src={urlYT} title={items.titulo} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <h3 className="tracking-wide font-extralight mt-2 text-sm">{items.date}</h3>
            <p className="tracking-wide text-sm font-bold text-gray-700 mt-2">{items.titulo}</p>
            <p className="tracking-wide text-sm mt-5">{items.descricao}</p>
            <div>
            <div className="flex justify-end mx-8 mt-10">
              <div id="rating-stars"></div>
              <button
                type="submit"
                className="py-3 px-11 bg-gray-50 rounded-md border-current"
                onClick={openModal}
              >
                Comentários
              </button>
              </div>

            </div>
          </div>
          <Transition appear show={isOpen} as={Fragment}>
		<Dialog
		  as="div"
		  className="fixed inset-0 z-10 overflow-y-auto bg-azul-escuro bg-opacity-75"
		  onClose={closeModal}
		>
		  <div className="min-h-screen px-4 text-center">
			<Transition.Child
			  as={Fragment}
			  enter="ease-out duration-300"
			  enterFrom="opacity-0"
			  enterTo="opacity-100"
			  leave="ease-in duration-200"
			  leaveFrom="opacity-100"
			  leaveTo="opacity-0"
			>
			  <Dialog.Overlay className="fixed inset-0" />
			</Transition.Child>

			<span
			  className="inline-block h-screen align-middle"
			  aria-hidden="true"
			>
			  &#8203;
			</span>
			<Transition.Child
			  as={Fragment}
			  enter="ease-out duration-300"
			  enterFrom="opacity-0 scale-95"
			  enterTo="opacity-100 scale-100"
			  leave="ease-in duration-200"
			  leaveFrom="opacity-100 scale-100"
			  leaveTo="opacity-0 scale-95"
			>
			  <div className="inline-block transition-all w-2/3 max-w-screen-md w-20 p-6 my-8 overflow-hidden text-left align-middle transform bg-white shadow-xl rounded-2xl">
				<Dialog.Title
				  as="h3"
				  className="text-lg font-medium leading-6 text-gray-900"
				>
				  Comente sobre este vídeo
				</Dialog.Title>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-2 sm:col-span-2 my-6">
              <textarea
                name="comentario"
                id="comentario"
                placeholder="Comentário"
                {...register("comentario",{required: true})}
                className="mt-1 focus:ring-indigo-500 focus:border-azul-ciano block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              ></textarea>
              </div>

            <div className="mt-4 flex justify-end">
              <button
              type="button"
              className="flex justify-end px-4 py-2 text-sm font-medium bg-azul-ciano text-white border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-8"
              onClick={closeModal}
              >
              Cancelar
              </button>
              <button
              type="submit"
              className="flex justify-end px-4 py-2 text-sm font-medium text-white bg-azul-escuro border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
              Enviar
              </button>
            </div>
          </form>
        </div>
        <div>
          <ul className="max-h-96 overflow-y-scroll">
            {comentarios}
          </ul>
        </div>
			  </div>
			</Transition.Child>
		  </div>
		</Dialog>
	  </Transition>
          {relacionados}
      </div>
      <Footer />
    </>
  );
}
}

export default Video;