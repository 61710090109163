import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

function Paginacao(props) {
    const items = [];

    for (let index = 1; index <= props.totPag; index++) {
        if (index === parseInt(props.pagAtual)) {
            items.push(<a
                href="#"
                key={index}
                aria-current="page"
                className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
                {index}
            </a>)
        } else {
            items.push(<a
                href={`/` + props.module+`?page=`+index }
                key={index}
                aria-current="page"
                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
                {index}
            </a>)
        }
    }
    return (
        <>
        <div className="sm:flex-1 sm:items-center sm:justify-between">
            <div>
                <nav className="z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <a
                        href={props.pagAtual > 1 ? `/` + props.module+`?page=`+(props.pagAtual - 1) : `#`}
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </a>
                    {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                    {items}
                    <a
                        href={parseInt(props.pagAtual) === parseInt(props.totPag) ? `#` : `/` + props.module+`?page=`+(parseInt(props.pagAtual) + 1)}
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </a>
                </nav>
            </div>
        </div>
        </>
    );
}

export default Paginacao