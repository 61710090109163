import React, {useEffect, useState} from 'react';
import Header from '../components/header'
import Footer from '../components/footer';
import PlanilhaThumb from '../components/planilha-thumb'
import api from '../services/api';
import Paginacao from '../components/paginacao';

function Planilhas() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  let token = sessionStorage.getItem('pautaberta_token');

  if (!token) {
    window.location.href = '/';
  }

  useEffect(() => {

    //if(!JSON.parse(sessionStorage.getItem('pautaberta_me')).member){
    //  window.location.href = JSON.parse(sessionStorage.getItem('pautaberta_me')).became_member
    //}
    let me = sessionStorage.getItem('pautaberta_me');
    me = JSON.parse(me);

    api
    .get('planilhas/?type='+me.type)
    .then(function (response) {
      setIsLoaded(true);
      setItems(response.data.data);
    })
    .catch(function (error) {
      setIsLoaded(true);
      setError(error);
    });
  }, [])
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <Header />
    );
} else {
  return (
    <>
      <Header />
      <div className="max-w-7xl px-8 mx-auto">
        <h2 className="text-3xl italic font-light px-8 my-7">Material de Apoio</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 max-w-7xl px-8 mx-auto pt-5 pb-10">
          {items.map(item => (
            <PlanilhaThumb titulo={item.titulo} descricao={item.descricao} thumb={item.imagem} download={!JSON.parse(sessionStorage.getItem('pautaberta_me')).member ? JSON.parse(sessionStorage.getItem('pautaberta_me')).became_member : item.arquivo}/>
            ))}
          <div className="lg:col-span-4 xl:col-span-4 md:col-span-3 sm:col-span-2 text-center">
              <Paginacao pagAtual="" totPag=""  />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
}
export default Planilhas;