import React from "react";

function EbookThumb(props) {
    return (
        <div className="w-full border rounded-lg">
            <img src={props.thumb} alt={props.titulo} className="rounded-t w-full" width="274" />
            <div className="px-2">
              <p className="tracking-wide text-sm font-bold text-gray-700 my-5 ml-2">{props.titulo}</p>
              <p className="tracking-wide text-sm my-5 ml-2">{props.descricao}</p>
            </div>
            <div className="border-t mt-4 p-4 text-center bottom-0">
              <a href={props.download}
                download
                className="inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-azul-ciano hover:bg-azul-escuro"
              >
                Download
              </a>
          </div>
        </div>
    )
};
export default EbookThumb;